@import "../../styles/fx";
@import "../../../mq";

.BgImageSlider {
  overflow: hidden;
  .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    &.s-not-loaded .bg {
      opacity: 0;
    }
    &.s-loaded .bg {
      opacity: 1;
    }
    .bg {
      @include transition(opacity 0.3s ease-out);
    }
    .Loading {
      .inner {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }  
  .controls {
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include transition(opacity 0.25s ease-out);
    .icon {
      width: 10px;
      height: 33px;
      margin: 0px 5px;
      background-image: url(../../../images/graphics/roll-over-arrows.png);
      background-repeat: no-repeat;      
      @include retina {
        background-image: url(../../../images/graphics/roll-over-arrows@2x.png);
        background-size: 20px 66px;
      }
    }
    .control {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      @include transition(opacity 0.5s ease-out);
      &.nextControl {
        justify-content: flex-end;
        .icon {
          background-position: -10px 0px;
        }
        &:hover {
          .icon {
            background-position: -10px -33px;
          }
        }
      }
      &.prevControl {
        &:hover {
          .icon {
            background-position: 0px -33px;
          }
        }
      }
      &.disabled {
        cursor: default;
        opacity: 0;        
      }
    }
  }
  &.BgImageSlider-large {
    .icon {
      width: 25px;
      height: 88px;
      margin: 0px 15px;
      background-image: url(../../../images/graphics/roll-over-arrows-large.png);
      @include retina {
        background-image: url(../../../images/graphics/roll-over-arrows-large@2x.png);
        background-size: 50px 176px;
      }
    }
    .control {
      &.nextControl {
        .icon {
          background-position: -25px 0px;
        }
        &:hover {
          .icon {
            background-position: -25px -88px;
          }
        }
      }
      &.prevControl {
        &:hover {
          .icon {
            background-position: 0px -88px;
          }
        }
      }
    }    
  }
  &.BgImageSlider--noTouch {
    .inner {
      @include transition(left 0.5s ease-in-out);
    }
  }
  &.BgImageSlider--noControls {
    .controls {
      div {
        pointer-events: none;
        display: none;
      }
    }
  }  
}