@import "../../styles/fx";

.CircularControls {
  @include unselectable;
  cursor: pointer;
  width: 60px;
  height: 60px;
  font-size: 12px;
  @include useBoldFont();
  @include transition(all 0.25s ease-out);
  #bg {
    // opacity: 0.9;
  }
  &:hover {
    @include transform(scale(1.25));
  }
}