.GalleryFloorOverviewSection {
  display: inline-block;
  margin-right: 70px;
  margin-bottom: 1.5em;
}

.GalleryFloorOverviewSection .thumb {
  cursor: pointer;
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #eee;
  margin-right: 10px;
}

.GalleryRoomOverview.u-mobile .GalleryFloorOverviewSection .thumb {
  width: 70px;
  height: 70px;
  margin-right: 5px;
}