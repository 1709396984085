.GalleryRoomOverview-wrapper {
  max-width: 1240px;
  margin: auto;
}
.GalleryRoomOverview {
  font-family: corporate-s, sans-serif;
  font-style: normal;
  font-weight: 500;  
}

.GalleryRoomOverview .title {
  font-weight: 800;
  font-size: 1.85em;
  margin-bottom: 1em;
}

.GalleryFloorOverview {
  margin-bottom: 3em;
}

.GalleryRoomOverview .sections{
  display: inline;
}

@media (max-width: 1290px) {
  .GalleryRoomOverview-wrapper {
    padding-left: 25px;
    padding-right: 25px;    
  }
}