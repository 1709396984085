.GalleryFloorView {
  // font-family: corporate-s, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.GalleryFloorView > section {
  margin-bottom: 3em;
}

.GalleryFloorView > section .title {
  font-size: 1.85em;
  margin-bottom: 0.35em;
  font-weight: 500;
}

.GalleryFloorView > section .features {
  margin-bottom: 1em;
}