.ActionButton {
  display: inline-block;
  cursor: pointer;
  height: 28px;
  font-size: 13px;
  line-height: 16px;
  padding: 5px 10px; 
  border: 1px solid #979797;
  border-radius: 28px;
  min-width: 50px;
  margin-bottom: 5px;
  user-select: none;
  text-align: center;
}

.mq-sm .ActionButton {
  height: auto;
  font-size: 16px;
}

.ActionButton.s-primary {
  background-color: #0085D6;
  border-color: #0085D6;
  color: #fff;
}

.ActionButton.s-link {
  border: none;
}

.ActionButton.s-primary.s-active:hover {
  background-color: #0085D6;
}

.ActionButton .label {
  display: inline-block;
  padding: 0px 5px;
}

.ActionButton.s-active:hover {
  background-color: #eee;
}

.ActionButton.s-inactive {
  opacity: 0.5;
  pointer-events: none;
}