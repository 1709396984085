$caps_font: "bebas-neue",sans-serif;

@mixin useArticleFont() {
  font-family: "corporate-a", serif;
  font-weight: 300;
}

@mixin useArticleFontItalic() {
  font-family: "corporate-a", serif;
  font-weight: 300;
  font-style: italic;
}

@mixin useHeadlineFont() {
  font-family: "bebas-neue", sans-serif;
  font-weight: 400;
  line-height: 0.85em;
}

@mixin useCaptionFont() {
  font-family: "corporate-a", serif;
  font-weight: 300;  
  font-style: italic;
}

@mixin useDefaultFont() {
  font-family: "corporate-s", sans-serif;
  font-weight: 300;
}

@mixin useBoldFont() {
  font-family: "corporate-s", sans-serif;
  font-weight: 700;
}

@mixin applyTopListColor($color, $color2) {
  border-bottom: 4px solid $color;
  .texts {
    .pretitle {
      color: $color2;
    }
  }  
}

@mixin genFactsIcon($kind) {
  background-image: url("/images/graphics/icon-"+$kind+".png");
  @include retina {
    background-image: url("/images/graphics/icon-"+$kind+"@2x.png");
  }  
}

@mixin clearfix {
  .cf:before,
  .cf:after {
      content: " ";
      display: table;
  }
  .cf:after {
      clear: both;
  }
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin rotation($deg) {
  -webkit-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  transform: $transform; 
}
@mixin transformOrigin($transform...) {
  -webkit-transform-origin: $transform;
  transform-origin: $transform; 
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-top($radius) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;

  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-radius-bottom($radius) {
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;

  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-radius-left($radius) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;

  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-radius-right($radius) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;

  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin no-touch-flash {
  -webkit-tap-highlight-color: transparent;
}

@mixin display-flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-column($nr) {
  -webkit-box-flex: $nr;
  -moz-box-flex: $nr;
  -ms-flex: $nr;
  -webkit-flex: $nr;
  flex: $nr;
}

@mixin box-orient-column {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@mixin table-row-bg {
  background: -webkit-linear-gradient(top, #000 1px, transparent 2px, transparent 40px, #000 41px, #1c1c1c 42px, #1c1c1c 100%);
  background: -moz-linear-gradient(top, #000 1px, transparent 2px, transparent 40px, #000 41px, #1c1c1c 42px, #1c1c1c 100%);
  background: -o-linear-gradient(top, #000 1px, transparent 2px, transparent 40px, #000 41px, #1c1c1c 42px, #1c1c1c 100%);
  background: -ms-linear-gradient(top, #000 1px, transparent 2px, transparent 40px, #000 41px, #1c1c1c 42px, #1c1c1c 100%);
  background: linear-gradient(top, #000 1px, transparent 2px, transparent 40px, #000 41px, #1c1c1c 42px, #1c1c1c 100%);
}

@mixin backgroundImageWithRetina($baseUrl, $extension) {
  background-image: url($baseUrl + "." + $extension);
  @include retina {
    background-image: url($baseUrl + "@2x." + $extension);
  }        
}

@mixin flex-basis($vals...) {
  flex-basis: $vals;
  -webkit-flex-basis: $vals; 
  -ms-flex-basis: $vals; 
}
