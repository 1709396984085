@import "../../styles/fx";

.ProgressDots {
  @include unselectable;
  display: flex;
  $size: 6px;
  justify-content: center;
  .item {
    cursor: pointer;
    padding: $size*2 $size;
    &.s-selected .icon {
      background-color: #fff;
      border-color: #fff;
    }
  }
  &.s-not-active {
    pointer-events: none;
    .item {
      cursor: default;
    }
  }
  .icon {
    width: $size;
    height: $size;
    border: 1px solid #fff;
    @include border-radius($size);
    @include transition(all 0.3s ease-out);
  }  
}