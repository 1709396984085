.TextInputField .TextInput {
  /* Rectangle 8: */
  background-color: #FFFFF9;
  border: none;
  height: 35px;
  outline: none;
  padding: 0px 8px;
  width: 100%;
  color: #000;
  margin-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid #000;
}

.TextInputField .TextInput.s-focused {
  border-color: #B09F59;
}

.size-large .TextInputField .TextInput {
  height: 40px;
  background-color: #fff;
}

.TextInputField .TextInput.s-inactive {
  background: transparent;
  opacity: 0.5;
}

.TextInputField .TextInput::-webkit-input-placeholder { /* Chrome */
  color: #aaa;
}
.TextInputField .TextInput :-ms-input-placeholder { /* IE 10+ */
  color: #aaa;
}
.TextInputField .TextInput ::-moz-placeholder { /* Firefox 19+ */
  color: #aaa;
  opacity: 1;
}
.TextInputField .TextInput :-moz-placeholder { /* Firefox 4 - 18 */
  color: #aaa;
  opacity: 1;
}