@import "../fx";
@import "../mq";

.PopupContainer {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 200;
  pointer-events: none;
  @include transition(background-color 0.5s ease-in-out);
  &.PopupContainer--active {
    pointer-events: auto;
    background-color: rgba(0,0,0,0.6);  
  }
}