.Loading {
  display: flex;
  justify-content: center;
  align-items: center;    
}

.Loading .inner {
  width: 60px;
  height: 60px;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;    
  border-radius: 100px;
}

.Loading .icon {
  width: 50px;
  height: 50px;
  background-image: url(/images/graphics/preloader-wheel.png);
  background-size: 50px 50px;
}