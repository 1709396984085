@import "./fx";

.Pages {  
  position: relative;
  z-index: 1;
  width: 100%;
  overflow-x: hidden;
  @include transition(all 0.3s ease-out);
}

.PagesUnderlay {
  position: fixed;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  @include transition(all 0.3s ease-out);
}

