@import "../../../mq";
@import "../../styles/fx";

.Intro {
  .title {
    font-size: 4.75em;
    line-height: 0.85em;
    margin: 0px;
  }
  .pretitle, .subtitle {
    display: inline-block;
    font-size: 1.25em;
    line-height: 1.25em;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }
  &.Intro-sm {
    font-size: 0.5em;
    .pretitle, .subtitle {
      font-size: 2em;
    }
  }
  &.Intro-md {
    font-size: 0.75em;
  }
  &.Intro-lg {
    font-size: 1em;
  }
  &.Intro-xl {
    font-size: 1.25em;
  }

  
  &.Intro-bold,
  &.Intro-bold-mix {
    @include useHeadlineFont();
    text-transform: uppercase;
    &.Intro-sm {
      font-size: 0.5em;
      .pretitle, .subtitle {
        font-size: 2em;
      }
    }
    &.Intro-md {
      font-size: 0.75em;
    }
    &.Intro-lg {
      font-size: 1em;
    }
    &.Intro-xl {
      font-size: 1.25em;
    }
    .title {
      font-size: 6em;
    }
  }
  &.Intro-article {
    .title {
      line-height: 1em;
      @include useArticleFont();
    }
    .pretitle {
      margin-bottom: 0.5em;
    }
    &.Intro-sm {
      font-size: 0.4em;
      .pretitle, .subtitle {
        font-size: 2em;
      }
    }
    &.Intro-md {
      font-size: 0.5em;
    }
    &.Intro-lg {
      font-size: 1em;
    }
    &.Intro-xl {
      font-size: 1.25em;
    }
    .title {
      font-size: 6em;
    }    
  }
  &.Intro-bold {
    .pretitle, .subtitle {
      @include useDefaultFont();
      font-size: 1em;
      line-height: 1.5em;
    }
    &.Intro-sm {
      .pretitle {
        font-size: 1.5em;
        line-height: 1em;
        margin-bottom: 1em;
      }
    }
  }
  &.Intro-bold-mix {
    .pretitle, .subtitle {
      @include useArticleFontItalic();
      text-transform: none;
      letter-spacing: 0.15em;
      line-height: 2em;
    }
    &.Intro-sm {
      .pretitle, .subtitle {
        font-size: 1.5em;
      }
    }
  }

  &.Intro-normal {
    .title {
      line-height: 1em;
    }
    .pretitle {
      margin-bottom: 0.5em;
    }
    &.Intro-xs {
      font-size: 0.4em;
    }
    &.Intro-sm {
      font-size: 0.4em;
      .pretitle, .subtitle {
        font-size: 2em;
      }
    }
    &.Intro-md {
      font-size: 0.5em;
    }
    &.Intro-lg {
      font-size: 1em;
    }
    &.Intro-xl {
      font-size: 1.25em;
    }
    .title {
      font-size: 6em;
    }    
  }

}

@media screen and (min-width: 0px) and (max-width: 670px) {
  .Intro .title {
    font-size: 3em;
  }
}