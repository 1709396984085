@import "../../styles/fx";

.ArticleWindow {
  > .closer {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  > .inner {
    background-color: #fff; 
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
  }
  .DynamicPage {
    height: inherit;
    overflow-y: auto;
  }
  .CloseIcon {
    cursor: pointer;
    position: absolute;
    width: 29px;
    height: 29px;
    top: 25px;
    right: 25px;
    padding-left: 2px;
    padding-top: 2px;
    z-index: 100;
    background: #fff;
    border-radius: 25px;
    @include box-shadow(0px 2px 2px rgba(0,0,0,0.2));
    #icon {
      // stroke: #059F54;
      stroke: #222;
    }
    &:hover {
      background: #000;
      #icon {
        stroke: #fff;
      }
    }    
  }
}
