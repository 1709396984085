@import "../../styles/fx";

.DynBgImageSlider {
  position: relative;
  .CircularControls {
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -30px;
    bottom: 25px;
  }
}
.iOS .DynBgImageSlider .CircularControls {
  @include transform(scale(1));
}