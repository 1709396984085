@import "../fx";

.NavigationMenu {
  position: fixed;
  z-index: 10;
  left: 0px;
  top: 0px;
  width: 100%;
  max-width: 400px;
  height: 100%;
  user-select: none;
}

.NavigationMenu.u-mobile {
  max-width: none;
}

.NavigationMenu.s-closed {
  pointer-events: none;
}

.NavigationMenu.s-closed .MenuTrigger {
  pointer-events: all;
}

.NavigationMenu.s-closed .MenuTrigger #grp {
  stroke: #fff;
}

.NavigationMenu .MenuTrigger #grp {
  stroke: #000;
}


.NavigationMenu.s-closed .panel {
  opacity: 0;
}

.NavigationMenu .panel {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #fff;
  display: flex;
  opacity: 1;
  @include transition(opacity 0.3s ease-out);
  @include box-shadow(1px 0px 3px rgba(0,0,0,0.1));
}

.NavigationMenu .inner {
  max-width: 300px;
  margin: auto;  
  padding: 1em 25px;
}

.NavigationMenu nav {
  margin-bottom: 2em;
}

.NavigationMenu nav a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: 1.4em;
  @include useBoldFont();
  padding: 0.5em 0px;
}

.NavigationMenu .poweredBy {
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.NavigationMenu .poweredBy a {
  color: inherit;
  text-decoration: none;
}

.NavigationMenu .poweredBy span {
  @include useBoldFont();
}

.NavigationMenu .MenuTrigger {
  position: absolute;
  z-index: 2;
  width: 50px;
  height: 50px;
  left: 10px;
  top: 0px;
  cursor: pointer;
}

.NavigationMenu.u-mobile .MenuTrigger {
  left: unset;
  right: 10px;
  top: -4px;
}