@import "../../mq";
@import "../../fx";

.module {
  width: 100%;
  height: 100%;
  background-color: #eee;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inner {
  max-width: 600px;
  padding: 0px 25px;
}

.pretitle {
  @include useArticleFont();
  font-style: italic;
  letter-spacing: 0.1em;
  color: #B8A668;
  line-height: 1.5em;  
}

.module h1 {
  font-size: 4em;
  @include useHeadlineFont();
  line-height: 1em;
  color: inherit;
  margin: 0px;
}