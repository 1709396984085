@import "../../styles/fx";

.ActionButton {
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-width: 2px;
  border-style: solid;
  border-color: #333;
  padding: 0.25em 1em;
  padding-bottom: 0.35em;
  background-color: #333;
  font-size: 0.85em;
  color: #ddd;
  @include transition(all 0.3s ease-out);
  &.ActionButton-hollow {
    background: none;
    color: #333;
  }
  @include border-radius(25px);
  a {
    text-decoration: none;
  }
  &.size-lg {
    font-size: 1em;
  }
  &.ActionButton-square {
    @include border-radius(0px);
  }
}
