.GalleryMobileMenu .GalleryFloorsMenu {
  padding-left: 0px;
  margin-left: 0px;
}

.GalleryMobileMenu .GalleryFloorsMenu .item {
  justify-content: center;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 5px 0px;
}

.GalleryMobileMenu .GalleryFloorsMenu .item .icon {
  display: none !important;  
}

.GalleryMobileMenu .view .icon {
  background-size: 26px 96px;
  transition: all 0.2s ease-out;
  display: none;
}

.GalleryMobileMenu .view.floorView {
  margin-right: 20px;
}

.GalleryMobileMenu .view.floorView .icon {
  background-position: 0px 0px;
}
.GalleryMobileMenu .view.floorView.u-selected .icon {
  background-position: 0px -24px;
}
.GalleryMobileMenu .view.overviewView .icon {
  background-position: 0px -48px;
}
.GalleryMobileMenu .view.overviewView.u-selected .icon {
  background-position: 0px -72px;
}

.GalleryMobileMenu .view.overviewView {
  margin-right: 60px;
}

.GalleryMobileMenu .icon {
  background-color: #000;
  width: 26px;
  height: 24px;
}

.MobileFilterMenu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  font-family: corporate-s, sans-serif;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0px -1px 3px rgba(0,0,0,0.2);
}

.MobileFilterMenu .caret {
  position: relative;
  display: inline-block;
  width: 5px;
  height: 1em;
  margin-left: 2px;
}

.MobileFilterMenu .caret .innerCaret {
  position: absolute;
  display: inline-block;
  top: 0.5em;
  width: 5px;
  height: 5px;
  border-top: 1px solid #999;
  border-right: 1px solid #999;
  transform: rotate(135deg);  
}

.MobileFilterMenu .u-selected .caret .innerCaret {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

.MobileFilterMenu .panel {
  border-top: 1px solid #eee;
  padding: 15px;
}

.MobileFilterMenu .view {
  display: flex;
  margin-right: 1em;
  color: #999;
  cursor: pointer;  
}
.MobileFilterMenu .view.u-selected {
  color: #000;
}

.MobileFilterMenu .statusbar {
  height: 50px;
  display: flex;
  padding: 0px 15px;
  align-items: center;
}

