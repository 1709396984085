.GalleryFloorsMenu {
  font-style: normal;
  font-weight: 300;  
  margin-left: 10px;
}
.GalleryFloorsMenu .item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.35em;
}

.GalleryFloorsMenu .item .icon {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
}

.GalleryFloorsMenu .item .innerIcon {
  position: absolute;
  overflow: hidden;
  left: -10px;
  top: 5px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  opacity: 0;
  transition: all 0.2s ease-out;
}

.GalleryFloorsMenu .item.u-selected .innerIcon {
  left: -5px;
  opacity: 1;
}

.GalleryFloorsMenu .item.u-selected {
  font-weight: 800;  
}

@media (max-width: 1290px) {
  .GalleryFloorsMenu {
    padding-left: 25px;
  }
}