@import "../../styles/fx";

.Title {
  @include useDefaultFont();
  margin-bottom: 0px;
  text-align: inherit;
  color: inherit;
  line-height: 0.85em;
  &.Title-sm {
    font-size: 1em;
  }
  &.Title-md {
    font-size: 2em;
  }
  &.Title-lg {
    font-size: 3.5em;
  }
  &.Title-xl {
    font-size: 4.5em;
  }  

  &.Title-bold {
  @include useHeadlineFont();
    &.Intro-sm {
      font-size: 0.5em;
      .pretitle, .subtitle {
        font-size: 2em;
      }
    }
    &.Intro-md {
      font-size: 0.75em;
    }
    &.Intro-lg {
      font-size: 1em;
    }
    &.Intro-xl {
      font-size: 1.25em;
    }
  }
  &.Title-article {
    text-transform: none;
    line-height: 1em;
    @include useArticleFont();
  }
  &.Title-italic {
    @include useArticleFont();
    font-style: italic;
    line-height: 1.2em;
  }
  p {
    margin: 0;
  }
  b, strong {
    font-weight: bold;
  }
}

