@import "../../styles/fx";

.ViewVideoButton {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 250px;
  height: 60px;
  background: #111;
  @include border-radius(4px);
  font-size: 0.9em;
  @include useBoldFont();
  color: #888;
  @include box-shadow(0px 1px 3px rgba(0,0,0,0.5));
  text-align: left;

  &:hover {
    .subtitle {
      color: #f00;
    }
  }
  .cover {
    position: absolute;
    background: #111;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 100%;
    @include border-radius-left(4px);
  }
  #PlayButton {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;
  }
  .text {
    padding-top: 20px;
    margin-left: 120px;
  }
  .title {
    text-transform: uppercase;
    line-height: 0.8em;
  }
  .subtitle {
    @include transition(color 0.2s ease-out);
    font-size: 0.75em;
    color: #34b062;
  }
}

.iOS .ViewVideoButton {
  width: 200px;
  height: 40px;  
  .cover {
    width: 75px;
  }
  #PlayButton {
    @include transform(scale(0.75));
  }
  .text {
    padding-top: 12px;
    margin-left: 90px;
  }
  .title {
    line-height: 0.5em;
  }
}