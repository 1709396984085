@import "../../styles/fx";

.VideoPlayer {
  $hiliteColor: #C5B47B;
  $bufferColor: #444;
  $progressColor: #C5B47B;
  overflow: hidden;
  
  &.VideoPlayer-fill {  
    video {
      position: relative;
      min-width: 102%;
      min-height: 102%;
      top: 51%;
      left: 51%;
      transform: translate(-51%, -51%);        
    }    
  }

  &.VideoPlayer-fit {
    video {
      width: 102%;
      height: 102%;
      top: 51%;
      left: 51%;
      transform: translate(-51%, -51%);
    }
  }

  .VideoBox-controls,
  .video_controls {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    background: rgba(0,0,0,0.8);

    .volume {
      display: none;
    }
  }

  .VolumeButton {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 20px;
    height: 20px;
    #muted-svg-icon,
    #loud-svg-icon {
      fill: #fff;
      stroke: #fff;
    }
    &:hover {
      #muted-svg-icon,
      #loud-svg-icon {
        fill: $hiliteColor;
        stroke: $hiliteColor;
      }
    }
  }

  .VideoBoxTimeline,
  .timeline {
    cursor: pointer;
    position: absolute;
    left: 60px;
    right: 60px;
    height: 3px;
    padding-top: 19px;
    padding-bottom: 18px;
  }

  .VideoBoxTimeline-track,
  .progress_bar {
    position: relative;
    width: 100%;
    height: 2px;
    background: #222;
  }

  .VideoBoxTimeline-progress,
  .playback_percent {
    position: absolute;
    top: 0px;
    z-index: 1;
    background: $progressColor;
    height: 2px;
  }

  .VideoBoxTimeline-buffer,
  .buffer_percent {
    position: absolute;
    z-index: 0;
    background: $bufferColor;
    height: 2px;
  }

  .PlaybackButton {
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 10px;
    width: 20px;
    height: 20px;
    #play-svg-icon,
    #pause-svg-icon {
      fill: #fff;
    }
    &:hover {
      #play-svg-icon,
      #pause-svg-icon {
        fill: $hiliteColor;
      }
    }
  }

}