.ViewVimeoIconButton {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.ViewVimeoIconButton.u-theme-light #PlayButton #bg {
  stroke: #000000;
}
.ViewVimeoIconButton.u-theme-light #PlayButton #play {
  fill: #000000;
}