.LoginPage {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  display: flex;
}

.LoginPage > .inner {
  position: relative;
  max-width: 470px;
  width: 100%;
  margin: auto;
  z-index: 1;
  padding: 20px;
}

.LoginPage .dialog {
  flex: 1 1 100%;
  background-color: #fff;
}

.LoginPage .u-top-spacer {
  flex: 1 0 25px;
  min-height: 25px;
}

.LoginPage .u-bottom-spacer {
  flex: 1 0 25px;
  min-height: 25px;
}

.LoginPage .header {
  background-color: #000;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 3em 25px 1em;
}

.LoginPage .content {
  padding: 25px;
}

.LoginPage .footer {
  padding: 0px 25px 25px;
  text-align: center;
}

.LoginPage .worldsfinest-logo {
  width: 140px;
  height: 13px;
  background-image: url("https://cdn.wfi.ag/pages/icons/worldsfinest-small-white-2.png");
  background-repeat: no-repeat;
  background-size: 140px 13px;
}

.LoginPage .auth-logo {
  max-width: 240px;
  max-height: 125px;
  margin-top: 2em;
  margin-bottom: 1em;
}

.LoginPage .auth-access-email a {
  display: block;
}

.LoginPage .AuthForm,
.LoginPage .AuthForm .TextInput {
  text-align: center;
}

.LoginPage .AuthForm .fields {
  margin-bottom: 1em;
}

.LoginPage .ActionButton {
  border-radius: 0px;
  background-color: #000;
  border: none;
}

.LoginPage .ActionButton.s-primary:hover {
  background-color: #25e968;
  color: #000;
}

.LoginPage .FormError {
  color: #a00;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.LoginPage .VideoPlayer {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}