.GalleryViewTypes-wrapper {
  /*max-width: 1240px;*/
  /*margin: auto;*/
}

.GalleryViewTypes {
  /*display: flex;*/
  /*margin-left: 25px;*/
  margin-top: 0.5em;
  margin-bottom: 2em;
  text-transform: uppercase;
  font-family: corporate-s, sans-serif;
  font-style: normal;
  font-weight: 800;  
  font-size: 0.75em;
  color: #CACACA;
}

.GalleryViewTypes .item {
  margin-right: 2em;
  display: flex;
  cursor: pointer;
  margin-bottom: 1.5em;
}
.GalleryViewTypes .item.u-selected {
  color: #4A4A4A;
}

// .GalleryViewTypes .icon {
//   margin-right: 1em;
//   width: 26px;
//   height: 24px;
//   background: url(/graphics/view-types.png);
//   background-repeat: no-repeat;
//   background-size: 26px 96px;
//   transition: all 0.2s ease-out;
// }
// .GalleryViewTypes .icon.icon-floor {
//   background-position: 0px 0px;
// }
// .GalleryViewTypes .u-selected .icon.icon-floor {
//   background-position: 0px -24px;
// }
// .GalleryViewTypes .icon.icon-overview {
//   background-position: 0px -48px;
// }
// .GalleryViewTypes .u-selected .icon.icon-overview {
//   background-position: 0px -72px;
// }

// @media
// only screen and (-webkit-min-device-pixel-ratio: 2),
// only screen and (   min--moz-device-pixel-ratio: 2),
// only screen and (     -o-min-device-pixel-ratio: 2/1),
// only screen and (        min-device-pixel-ratio: 2),
// only screen and (                min-resolution: 192dpi),
// only screen and (                min-resolution: 2dppx) {
//   .GalleryViewTypes .icon {
//     background: url(/graphics/view-types@2x.png);
//     background-size: 26px 96px;
//   }
// }


@media (max-width: 1290px) {
  .GalleryViewTypes-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}