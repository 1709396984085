@import "../../styles/fx";

.LayoutContainer {
  transition: height 0.3s ease-out;
  .inner {
    position: relative;
    z-index: 1;
  }
  &.withBgVideo {
    .VideoPlayer {
      position: absolute;
      // z-index: 0; // This is causing a bug with the containing video showing white borders on fractional widths.
      left: 0px;
      top: 0px;
    }
    .inner {
      position: relative;
      z-index: 1;
    }
  }
}