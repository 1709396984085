@import "../../styles/fx";

.VideoPlayerWindow {
  // Intentionally the same. Use opacity instead.
  $blurColor: #fff;
  $hiliteColor: #fff;

  .topVignette {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(-180deg, rgba(24,24,24,0.5) 0%, rgba(24,24,24,0) 100%);
  }

  .bottomVignette {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(0deg, rgba(24,24,24,0.5) 0%, rgba(24,24,24,0) 100%);
  }

  .vignette {
    opacity: 0;
    @include transition(opacity 0.5s ease-in-out);
  }

  .shim {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    @include transition(opacity 0.5s ease-out);
  }

  &.VideoPlayerWindow--focus {
    .shim {
      opacity: 0.5 !important;
    }
    .vignette {
      opacity: 1;
    }
    .video_controls {
      opacity: 1;
    }
    .LogoContainer {
      opacity: 1;
    }
    .close_video {
      opacity: 1;
    }
  }

  &.VideoPlayerWindow--controls {
    .video_controls {
      bottom: 15px;
    }
    .shim {
      opacity: 0.25;
    }
  }

  .LogoContainer {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 10;
    opacity: 0.5;
    @include transition(opacity 1s ease-out);
    @include unselectable;

    .WFLogo {
      #wf {
        fill: $blurColor;
      }
      #circle {
        stroke: $blurColor;
      }
    }

    .Logotype {
      margin-left: 2px;
      font-size: 0.85em;
      display: inline-block;
      text-transform: uppercase;
      font-weight: lighter;
      white-space: nowrap;
      letter-spacing: 0.05em;
      color: $blurColor;
      strong {
        @include useBoldFont();
      }
      position: absolute;
      left: 55px;
      top: 14px;
    }

  }

  .close_video {
    cursor: pointer;
    position: absolute;
    top: 44px;
    right: 50px;
    z-index: 10;
    background: none;
    @include useBoldFont();
    font-size: 0.85em;
    text-transform: uppercase;
    color: $blurColor;
    @include transition(all 0.3s ease-out);
    &:hover {
      opacity: 1;
      color: #C51D31;
    }
    opacity: 0.5;
  }

  .video_controls {
    background: none;
    bottom: -50px;
    z-index: 100;
    opacity: 0.5;
    @include transition(opacity 1s ease-out, bottom 0.4s ease-in-out);
  }
  .progress_bar {
    height: 2px;
    background-color: rgba(255,255,255,0.2);
    @include border-radius(10px);
  }

  .play_head {
    position: absolute;
    z-index: 1;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    margin-top: -5px;
    @include border-radius(100%);
    background-color: #fff;
    @include box-shadow(0px 1px 3px rgba(0,0,0,0.5));
    @include transition(left 0.3s ease-in);
  }

  .PlaybackButton,
  .VolumeButton {
    top: 8px;
    width: 25px;
    height: 25px;
    #muted-svg-icon,
    #loud-svg-icon {
      fill: $blurColor;
      stroke: $blurColor;
    }
    &:hover {
      #muted-svg-icon,
      #loud-svg-icon {
        fill: $blurColor;
        stroke: $blurColor;
      }
    }    
  }
  .PlaybackButton {
    left: 30px;
    #play-svg-icon,
    #pause-svg-icon {
      fill: $blurColor;      
    }
    &:hover {
      #play-svg-icon,
      #pause-svg-icon {
        fill: $blurColor;
      }
    }
  }
  .VolumeButton {
    right: auto;
    left: 80px;
  }
  .timeline {
    left: 140px;
    right: 50px;
  }
  .playback_percent,
  .buffer_percent {
    height: 100%;
    @include border-radius(10px);
    @include transition(width 0.3s ease-in);
  }
  .playback_percent {
    background-color: #C51D31;
  }
  .buffer_percent {
    background-color: rgba(255,255,255,0.5);
  }
}