@import-normalize;
@import "./fx";
@import "./mq";

html {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  margin: 0px;
  -ms-overflow-style: none;
}

*, *:before, *:after {
  box-sizing: inherit;
}

*::selection, *:before::selection, *:after::selection {
    color: #fff;
    background-color: #f00;
}

a {
  color: inherit;
}

body {
  /*position: relative;*/
  color: #333;
  margin: 0px;
  @include useDefaultFont();
  font-size: 15px;
  line-height: 20px;
  -webkit-tap-highlight-color: transparent;
  
  .PopupContainer {
    font-size: 15px;
  }
}

p.lead {
  font-size: 1.25em;
  line-height: 2em;
  font-weight: normal;
}

blockquote {
  @include useArticleFont();
  font-size: 4em;
  line-height: 1em;
  font-weight: lighter;    
}
em {
  color: #C5B47B;
  font-style: normal;
}
blockquote::before {
  content: "\"";
}
blockquote::after {
  content: "\"";
}
