.PropertyGalleryBrowser {
  font-style: normal;
  font-weight: 500;    
  max-width: 1240px;
  margin: 6em auto !important;
}

.PropertyGalleryBrowser .floors {
  display: flex;
  margin-bottom: 2em;
}
.PropertyGalleryBrowser .floors .menu {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
}
.PropertyGalleryBrowser .floors .menu > .item {
  margin-right: 1em;
  margin-bottom: 0.25em;
  cursor: pointer;
}
.PropertyGalleryBrowser .floors .menu > .item.u-empty {
  display: none;
}
.PropertyGalleryBrowser .floors .button {
  white-space: nowrap;
  color: #B8A668;
  font-weight: 500;
}

.PropertyGalleryBrowser .floors .button a {
  text-decoration: none;
  color: inherit;
}

.PropertyGalleryBrowser .floors .menu > .item.u-selected {
  font-weight: 800;
  border-bottom: 1px solid #000;
}

.PropertyGalleryBrowser .floor {
  width: 100%;
  font-weight: 500;    
}

.PropertyGalleryBrowser .floor > div {
  display: inline-block;
  min-width: 284px;
  min-height: 190px;
  margin-right: 10px;
  margin-bottom: 1em;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.PropertyGalleryBrowser .floor > div .background-image {
  /*display: block;*/
  width: 284px;
  height: 190px;  
  margin-bottom: 0.25em;
}