@import "../../styles/fx";

.Paragraph {
  font-size: 1em;
  &.Paragraph--lead {
    font-size: 1.3em;
    line-height: 1.5em;
    p {
      .dropcaps {
        font-size: 5em;
        line-height: 0.9em;
        padding-right: 0.2em;
      }
    }
  }
  &.Paragraph-sm {
    font-size: 0.8em;
    line-height: 1.25em;
  }
  p {
    font-size: 1em;
    line-height: 1.5em;
    .dropcaps {
      @include useArticleFont();
      font-size: 4em;
      display: inline-block;
      line-height: 1em;
      float: left;
      padding-right: 0.1em;
    }    
  }
  &.Paragraph-article {
    @include useArticleFont();    
  }  

  strong {
    color: inherit;
    font-weight: bold;
  }
  em {
    color: inherit;
    font-style: italic;
  }
}