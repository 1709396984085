$phone: 0px 500px;
$app: 0px 950px;
$phone-landscape: 501px 670px;
$phone-and-landscape: 0px 670px;
$ipad-portrait: 569px 768px;
$all-small: 0px 748px;

$phone-landscape-height: max-height 414px;

$mobile: (max-width 500px, max-height 414px);

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}